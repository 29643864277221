import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { blockexplorers, environment } from 'src/environments/environment';
import { Big } from 'big.js';
import { ConfirmModal } from '../modals/confirm/confirm.modal';
import { InfoAnalyticCell, ModalInfoModel } from '../../model';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { docType } from '../../enums';
import QRCodeStyling from 'qr-code-styling';
import { StringExtensions } from '../../utils/string.extension';

const apiUrl = environment.apiUrl;
const bsCalendar = '#bsCalendar';

export abstract class BaseComponent {
  public promocodeValuePattern = /^[A-Z0-9]{1,10}$/;
  public confirmCodePattern = '[0-9]{6}';
  public confirm2FAPattern = /^[0-9]{6}$/;
  public ethAddressPattern = /^0x[a-fA-F0-9]{40}$/;
  public tonAddressPattern = /^(EQ|UQ|Ef|Uf|QA)[a-zA-Z0-9_-]{46}$/i;
  public floatNumberPattern = /^-?(0|[1-9]\d*)([.,]\d+)?$/;
  public linkPattern = '(https://){1}([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  public letterPattern = /^[äöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍa-zA-Zа-яёА-Я-\s]+$/;
  public emailPattern = /^[_a-z0-9-\+-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;
  public namePattern = /^[A-ZА-ЯЁ]+([- ][A-ZА-ЯЁ]+)*$/i; // todo: переделать чтобы нельзя было писать aBOba
  public latinPattern = /^[A-Z]+([- ][A-Z]+)*$/i;
  public companyNamePattern = /^[äöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍa-zA-Zа-яёЁА-Я0-9" ,.'-]+$/i;
  public latinAndNumberPattern = /^[A-Za-z0-9]+$/;
  public flightIdPattern = /^(?=(?:.*\d){3})[A-Za-z][A-Za-z\d]*$/;
  public youTubeLinkPattern = /https:\/\/(?:youtu\.be\/|(?:[a-z]{2,3}\.)?youtube\.com\/([\w-]+))/;
  public youTubeLinkPattern2 = /http(s)?:\/\/www\.youtube\.com\/watch\?v=([\w\-_=&]+)\&?/;
  public filePattern = /\.([a-zA-Z]+)$/;
  public URLPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
  public phonePattern = /^(?:[+]?\d+(?:\s?\(\d+\))?|\d{3}(?:\s?\(\d+\))?)(?:[-\s]?\d+)+$/;
  public numberPattern = /^[0-9]+$/;
  public cryptoRoundDecimals = 1e5;
  public usdRoundDecimals = 1e2;
  public dateFormat = 'yyyy-MM-dd';
  public defaultAvatarUrl: string = '/assets/images/avatar/default_avatar.png';
  public gwei = new Big(1000000000);
  public bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'DD.MM.YYYY',
    selectWeek: false,
    showWeekNumbers: false,
    isAnimated: true,
    customTodayClass: 'datepicker-custom-today-class',
  };
  public dateArray: any[] = [];

  //с помощью этого создается модалка
  private modalRefBase: NgbModalRef;

  constructor(
    private translateBase: TranslateService,
    private modalServiceBase: NgbModal,
  ) {}

  public setLoading(isLoading: boolean) {
    LoaderComponent.setLoading(isLoading);
  }

  public elemIsInvalid(elem: any): boolean {
    return elem.dirty && !elem.untouched && elem.invalid; //pristine еще есть он как дирти вроде
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public textErrorStr(elem: any, namepattern = null) {
    if (this.elemIsInvalid(elem)) {
      var customError = Object.getOwnPropertyNames(elem.errors);
      return elem.errors.required
        ? this.translateBase.instant('errors.required')
        : elem.errors.max != undefined
          ? this.translateBase.instant('errors.max') + ' ' + elem.errors.max.max
          : elem.errors.min != undefined
            ? this.translateBase.instant('errors.min') + ' ' + elem.errors.min.min
            : elem.errors.maxlength != undefined
              ? this.translateBase.instant('errors.maxLength') +
                elem.errors.maxlength.requiredLength
              : elem.errors.minlength != undefined
                ? this.translateBase.instant('errors.minLength') +
                  elem.errors.minlength.requiredLength
                : elem.errors.pattern != undefined &&
                    namepattern != null &&
                    namepattern == this.linkPattern
                  ? this.translateBase.instant('errors.linkPattern')
                  : elem.errors.pattern != undefined &&
                      namepattern != null &&
                      namepattern == this.latinAndNumberPattern
                    ? this.translateBase.instant('errors.onlyLatinAndNum')
                    : elem.errors.pattern != undefined &&
                        namepattern != null &&
                        namepattern == this.youTubeLinkPattern
                      ? this.translateBase.instant('errors.youTubeLinkPattern')
                      : elem.errors.pattern != undefined &&
                          namepattern != null &&
                          namepattern == this.youTubeLinkPattern2
                        ? "Enter the link in the format:'https://www.youtube.com/watch?v=pXRviuL6vMY'"
                        : elem.errors.pattern != undefined &&
                            namepattern != null &&
                            namepattern == this.emailPattern
                          ? this.translateBase.instant('errors.invalidEmail')
                          : elem.errors.pattern != undefined &&
                              namepattern != null &&
                              namepattern == this.namePattern
                            ? this.translateBase.instant('errors.invalidNameFormat')
                            : elem.errors.pattern != undefined &&
                                namepattern != null &&
                                namepattern == this.phonePattern
                              ? this.translateBase.instant('errors.incorrectPhoneFormat')
                              : elem.errors.pattern != undefined &&
                                  namepattern != null &&
                                  namepattern == this.URLPattern
                                ? this.translateBase.instant('errors.incorrectaddress')
                                : elem.errors.pattern != undefined &&
                                    namepattern != null &&
                                    namepattern == this.ethAddressPattern
                                  ? this.translateBase.instant('errors.incorrectethAddress')
                                  : elem.errors.pattern != undefined &&
                                      namepattern != null &&
                                      namepattern == this.tonAddressPattern
                                    ? this.translateBase.instant('errors.incorrectTonAddress')
                                    : elem.errors.pattern != undefined &&
                                        namepattern != null &&
                                        namepattern == this.confirm2FAPattern
                                      ? this.translateBase.instant('errors.incorrectconfirm2FA')
                                      : elem.errors.pattern != undefined &&
                                          namepattern != null &&
                                          namepattern == this.floatNumberPattern
                                        ? this.translateBase.instant('errors.inputNumber')
                                        : elem.errors.pattern != undefined &&
                                            namepattern != null &&
                                            namepattern == this.latinPattern
                                          ? this.translateBase.instant('errors.invalidLatinFormat')
                                          : elem.errors.pattern != undefined &&
                                              namepattern != null &&
                                              namepattern == this.numberPattern
                                            ? this.translateBase.instant('errors.inputNumber')
                                            : elem.errors.pattern != undefined &&
                                                namepattern != null &&
                                                namepattern == this.flightIdPattern
                                              ? this.translateBase.instant(
                                                  'errors.invalidFlightIdFormat',
                                                )
                                              : elem.errors.pattern != undefined &&
                                                  namepattern == this.promocodeValuePattern
                                                ? 'errors.invalidPromocode'
                                                : elem.errors.uniqueInvalid != undefined
                                                  ? this.translateBase.instant('errors.uniqueInvalid')
                                                  : elem.errors.email != undefined
                                                    ? this.translateBase.instant(
                                                        'errors.invalidEmail',
                                                      )
                                                    : elem.errors.mismatch != undefined
                                                      ? this.translateBase.instant('errors.mismatch')
                                                      : !!customError && customError.length > 0
                                                        ? this.translateBase.instant(customError[0])
                                                        : '';
    }
    return '';
  }

  // public textErrorField(elem: any,namepattern = null){
  //   if(this.elemIsInvalid(elem)){
  //     return elem.errors.required ? this.translateBase.instant("errors.required") :
  //     elem.errors.max!=undefined ? (this.translateBase.instant("errors.maxLength") + elem.errors.max.max) :
  //     elem.errors.min!=undefined ? (this.translateBase.instant("errors.minLength") + elem.errors.min.min) :
  //     elem.errors.pattern!=undefined && namepattern!=null ? (this.translateBase.instant("errors.unallowedChar")):"";
  //   }
  //   return "";
  // }

  public markFormGroupTouchedAndDirty(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupTouchedAndDirty(control);
      }
    });
  }

  public markFormGroupUnTouchedAndPristine(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupUnTouchedAndPristine(control);
      }
    });
  }

  protected showResponseError(response: any) {
    //если к чему то не можем обратиться то уходим
    if (!response || !response.error) {
      return;
    }
    if (!!response.error.errors && response.error.errors.length != 0) {
      for (let e of response.error.errors) {
        if (e.message == 'Chart loading error') continue;

        if (!!e.replaces && e.replaces.length > 0) {
          e.message = this.translateBase.instant(e.message);
          e.message = StringExtensions.format(e.message, e.replaces);
        }
        return this.showError(e.message);
      }
    } else if (!!response.error.error && !!response.error.error_description) {
      return this.showError(response.error.error_description);
    }
  }

  public shellConversion(num: number | string) {
    if (num != null && num != undefined) {
      if (!Number.isNaN(num))
        return parseFloat(num.toString()).toLocaleString('ru-RU').replace(',', '.');
      else return num.toString();
    }
  }

  public shellConversionString(num: string) {
    if ((num !== null || num != undefined) && !Number.isNaN(parseFloat(num)))
      return parseFloat(num).toLocaleString('ru-RU').replace(',', '.');
    return 0;
  }

  //метод который включает отображение модалки
  protected showModal(modalInfo: ModalInfoModel): Promise<any> {
    var t = this;

    t.modalRefBase = t.modalServiceBase.open(ConfirmModal, {
      backdropClass: 'light-white-backdrop',
      centered: true,
      size: 'md',
      windowClass: 'super-modal-delete-users very-nice-shadow',
    });

    //информативный блок
    t.modalRefBase.componentInstance.title = modalInfo.title;
    t.modalRefBase.componentInstance.description = modalInfo.description;
    t.modalRefBase.componentInstance.showDescription = modalInfo.showDescription;
    //блок с настройками кнопок
    t.modalRefBase.componentInstance.showConfirmButton = modalInfo.showConfirmButton;
    t.modalRefBase.componentInstance.showDeclineButton = modalInfo.showDeclineButton;
    t.modalRefBase.componentInstance.showErrorButton = modalInfo.showErrorButton;
    t.modalRefBase.componentInstance.buttonConfirm = modalInfo.buttonConfirm;
    t.modalRefBase.componentInstance.buttonDecline = modalInfo.buttonDecline;
    t.modalRefBase.componentInstance.buttonError = modalInfo.buttonError;
    // .then(result => {}) - result - результат нажатия на кнопки (true/false)
    // .catch((reason) => {}) - reason - результат выхода, при нажатии на крестик или мимо модалки (0)
    return t.modalRefBase.result;
  }

  // упрощенное представление модалки с ошибкой
  protected showError(message: string, titleMes: string = 'Attention'): Promise<any> {
    var t = this;

    var mes = t.translateBase.instant(message);
    var title = t.translateBase.instant(titleMes);

    var modalInfo = new ModalInfoModel();
    modalInfo.title = !!title ? title : mes;
    modalInfo.description = !!title ? mes : '';
    modalInfo.showConfirmButton = false;
    modalInfo.showDeclineButton = false;
    modalInfo.showErrorButton = true;

    return t.showModal(modalInfo);
  }

  //упрощенное представление модалки с успешным сообщением
  protected showSuccess(message: string, titleMes: string = 'System'): Promise<any> {
    var t = this;

    var mes = t.translateBase.instant(message);
    var title = t.translateBase.instant(titleMes);

    var modalInfo = new ModalInfoModel();
    modalInfo.title = !!title ? title : mes;
    modalInfo.description = !!title ? mes : '';
    modalInfo.showDeclineButton = false;

    return t.showModal(modalInfo);
  }

  //упрощенное представление модалки с подтверждением действия
  protected showConfirm(
    title: string,
    message: string,
    showDeclineButton: boolean = false,
    confirmButtonText: string = '',
  ): Promise<any> {
    var t = this;

    var modalInfo = new ModalInfoModel();
    //информативный блок
    modalInfo.title = t.translateBase.instant(title);
    modalInfo.description = t.translateBase.instant(message);
    modalInfo.showDeclineButton = showDeclineButton;
    modalInfo.buttonConfirm = confirmButtonText;
    return t.showModal(modalInfo);
  }

  public getMathFloor8(val: number): number {
    return Math.floor(val * 10 ** 8) / 10 ** 8;
  }

  public atLeastOneCheckboxCheckedValidator(nameofError: string, minRequired = 1): ValidatorFn {
    return function validate(formGroup: UntypedFormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return { 'errors.atLeastOneCheckbox': true };
      }

      return null;
    };
  }

  //TODO добавить в ошибку переменную strLength, чтобы выводилось любое число
  public noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length < 3;
    const isValid = !isWhitespace;
    return isValid ? null : { 'errors.whitespace': true };
  }

  public downloadFile(fileUrlStr: string) {
    var url = fileUrlStr.split('|');
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', apiUrl + url[0]); //url файла
    link.setAttribute('download', url[1]); //имя файла
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public getRatingFilterYear(): any[] {
    var currentYear = new Date().getFullYear();
    var chooseYear = [];
    //пушаем года от текущего до 2020ого
    while (currentYear >= 2020) {
      chooseYear.push({
        value: currentYear,
        name: currentYear.toString(),
        code: '',
        label: currentYear.toString(),
      });
      currentYear--;
    }

    return chooseYear;
  }

  public MathFloorBig(big: Big, accuracy: number) {
    var acc = new Big(10).pow(accuracy);
    return big.mul(acc).round(0, 0).div(acc);
  }

  public MathCeilingBig(big: Big, accuracy: number) {
    var acc = new Big(10).pow(accuracy);
    return big.mul(acc).round(0, 3).div(acc);
  }

  /** Метод округления.
  Cуществуют уже определённые переменные usdRoundDecimals и cryptoRoundDecimals,
  которые можно использовать */
  public defaultRound(num: number, decimals: number = this.cryptoRoundDecimals) {
    if (!num || Number.isNaN(num)) num = 0;
    var bigNum = new Big(num);
    var bigDecimals = new Big(decimals);
    return +bigNum.mul(bigDecimals).round(0, 0).div(bigDecimals);
  }

  public GetTheCurrentWeek() {
    var todayDate = new Date();
    var day = todayDate.getDate();
    var year = todayDate.getFullYear();
    var month = todayDate.getMonth();
    var tzoffset = new Date().getTimezoneOffset() * 60000;
    var dateStart = this.toStringFormat(new Date(+new Date(year, month, day - 7) - tzoffset));
    var dateEnd = this.toStringFormat(new Date(+new Date(year, month, day) - tzoffset));
    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public GetTheCurrentWeekDate() {
    var t = this;
    var dateSrtBE = t.GetTheCurrentWeek();
    var dateStart = new Date(dateSrtBE.dateStart);
    var dateEnd = new Date(dateSrtBE.dateEnd);
    return { dateStart: dateStart, dateEnd: dateEnd };
  }

  public toStringFormat(date: Date) {
    if (!!date) return date.toISOString().split('T')[0];
    else return '';
  }

  getCurrentLocalization() {
    return localStorage.getItem('localization') ?? 'en';
  }

  changeLanguage(lang) {
    localStorage.setItem('localization', lang);
    this.translateBase.use(lang);
  }

  changeOpeningIcon(calendarId = bsCalendar) {
    var input = document.querySelector(calendarId);
    if (input.classList.contains('iconShow')) {
      input.classList.remove('iconShow');
    } else {
      input.classList.add('iconShow');
    }
  }

  public setCalendarWidth(calendarId = bsCalendar) {
    var input = document.querySelector(calendarId);
    var calendar = document.querySelectorAll<HTMLElement>('.bs-calendar-container');
    calendar.forEach((e) => {
      e.style.width = input.clientWidth + 'px';
    });
  }

  public calendarClick(calendarId = bsCalendar) {
    var input = document.querySelector(calendarId) as HTMLInputElement;
    if (document.activeElement !== input) {
      input.focus();
    } else {
      input.blur();
    }
  }

  public calcBarPadding(barPadding, host, single, barVerticalComponent, barWidthPx) {
    //пустое пространство графика 2/3 выделенной области
    barPadding = (host.nativeElement.offsetWidth / (single.length * 3)) * 2;
    if (!!barVerticalComponent) {
      var barDims = barVerticalComponent.dims;
      var barVerticalComponentWidth = barDims.width;

      var barSizeSum = single.length * barWidthPx;
      var barPabbingSum = barVerticalComponentWidth - barSizeSum;
      barPadding = barPabbingSum / (single.length > 1 ? single.length - 1 : 1);
    }
    return barPadding;
  }

  public isValid(text: string, pattern: RegExp, isTouched: boolean = false) {
    if (text && pattern) {
      return pattern.test(text);
    }
    return !isTouched;
  }

  public getExtension(source: string, targets: string[]): boolean {
    var hasExt = false;
    targets.forEach((element) => {
      var filename = source.length - element.length;
      hasExt = hasExt || (filename >= 0 && source.indexOf(element, filename) == filename);
    });
    return hasExt;
  }

  public isWithdrAvailable(cells: InfoAnalyticCell[]) {
    var isAvailable = false;
    cells.forEach((section) => {
      isAvailable =
        section.sectionName != 'By all sections' && (isAvailable || section.isWithdrawalAvailable);
    });
    return isAvailable;
  }

  public openFileInNewTab(fileUrl: string) {
    window.open(apiUrl + fileUrl, '_blank');
  }

  public getBlockExplorerLink(
    address: string,
    search: string = 'address',
    exchange: string = 'etherscan',
  ) {
    return blockexplorers[exchange] + search + '/' + address;
  }

  public docUrl(type: docType) {
    return apiUrl + 'api/documentation/get/' + type + '/' + 'en'; // когда будет локализация, заменить en на this.getCurrentLocalization()
  }

  public showAgreementModal(): Promise<any> {
    let t = this;
    t.modalRefBase = t.modalServiceBase.open(ConfirmModal, {
      backdropClass: 'light-white-backdrop',
      backdrop: 'static',
      centered: true,
      size: 'md',
      windowClass: 'super-modal-delete-users very-nice-shadow',
    });

    t.modalRefBase.componentInstance.showTitle = false;
    t.modalRefBase.componentInstance.showDeclineButton = false;
    t.modalRefBase.componentInstance.description =
      "By clicking 'agree', you confirm your agreement with the terms of use of our platform that allow anyone, regardless of citizenship, purchasing TTA tokens through the platform. By expressing your agreement with our terms, you also confirm that you are not the resident of the United States of America, or, if you are the resident of the United States of America, you will not use our platform for purchasing TTA tokens.";
    t.modalRefBase.componentInstance.buttonConfirm = 'Agree';
    return t.modalRefBase.result.then((result) => {
      return result;
    });
  }

  public paste(input) {
    navigator.clipboard.readText().then((text) => {
      input.value = text;
    });
  }

  public copyText(text: string) {
    var t = this;
    navigator.clipboard.writeText(text).then(() => {
      t.showSuccess('Copied successfully');
    });
  }

  public getQrCodeStylingOptions(
    width,
    height: number,
    data: string,
    margin: number = 0,
  ): QRCodeStyling {
    return new QRCodeStyling({
      width: width,
      height: height,
      data: data,
      margin: margin,
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'M',
      },
      dotsOptions: {
        type: 'dots',
        color: '#fff',
      },
      backgroundOptions: {
        color: '#000',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#fff',
      },
      cornersDotOptions: {
        type: 'dot',
        color: '#fff',
      },
    });
  }

  /*  замена в строке другой строкой
      originalString - строка на вход,
      replacement - на какую строку заменить,
      startIndex - замена начиная с какого индекса,
      charactersToKeep - сколько символов оставить в конце */
  public replaceSubstringByIndex(
    originalString: string,
    replacement: string,
    startIndex: number,
    charactersToKeep: number,
  ) {
    return (
      originalString.substring(0, startIndex) +
      replacement +
      originalString.substring(originalString.length - charactersToKeep)
    );
  }

  public mathRound(value, precision = 0) {
    let multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }

  // генерация ссылка на встречу в гугл-календаре
  public generateGoogleCalendarLink(
    startDate: Date,
    endDate: Date,
    title: string,
    description: string,
  ): string {
    // форматирование даты в вид, который принимает гугл-календарь
    const formatDate = (date: Date) => {
      const pad = (num: number) => num.toString().padStart(2, '0');
      return `${date.getUTCFullYear()}${pad(date.getUTCMonth() + 1)}${pad(date.getUTCDate())}T${pad(date.getUTCHours())}${pad(date.getUTCMinutes())}${pad(date.getUTCSeconds())}Z`;
    };

    const start = formatDate(new Date(startDate));
    const end = formatDate(new Date(endDate));
    const uriTitle = encodeURIComponent(title);
    const uriDescription = encodeURIComponent(description);

    return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${uriTitle}&dates=${start}/${end}&ctz=UTC&details=${uriDescription}`;
  }
}
