import { CurrencyType } from "../enums";

export var limitsForPurchases = {
  minForTTA: 10, //в долларах
};

export var limitsForWithdraw = {
  minForTTA: 0.1, //в токенах
  minForPromocode: new Map<CurrencyType, number>([
    [CurrencyType.ETH, 0.01],
    [CurrencyType.USDTTon, 10],
  ])
};

export var limitsForSell = {
  minForTTA: 2, //в токенах
};
